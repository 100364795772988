import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import Content_StorageOptimized from "components/content/feature_storage-optimized.js";
import Content_StorageMigration from "components/content/feature_storage-migration.js";

const Page = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="saas-backup"
      title="Automated Knack data backup service"
      description="Automate your Knack backups, store them where your want."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated <span className="text-primary">Knack data</span> backup.</>)}
      subTitle={(
        <>Automated backup for your Knack databases.<br/>Schedule, backup and store your Knack backups where you want.
        </>)}
      icons={[
        <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
        <Ui.Image filename={`brands/icons/knack-ico.png`} alt={`Knack backup`}  className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your Notion Backup" urlAtrr={{ sb_source: "website", sb_term: `knack-hero` }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-hero-dashboard.png" alt="SimpleBackups Knack backup dashboard: all your backups in one place"/>
      )}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "Knack security",
                title: (<>Get peace of mind and secure your <span className="text-primary">Knack data</span></>),
                description: (
                  <>
                    <p>
                      <span className="link-highlight">No-code and no infrastructure required</span>. Specifically designed to backup SaaS applications data without the hassle.<br/>
                      We've partnered with Knack to offer complete peace of mind when it comes to your Knack data.<br/>
                      <Ui.FeatureChecks items={['Flexible Retention', 'Custom Scheduling', 'Serverless Backup']} />
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-backup-expert.png'
                  alt="Optimized MySQL backup service"
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureControl({subject: 'Knack'}),
              Content_FeatureTrust(),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Knack Backup, flexible and simple</Ui.Typography>

          <div className="grid md:grid-cols-4 md:gap-4 gap-4 text-left mt-16">
            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-binary-lock text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">Encrypted Knack Backups</h3>
              <p className="text-base">
                All you Knack data are <span className="link-highlight">encrypted</span> safeguarding it against unauthorized access during storage and transfer.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-flask-gear text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">Custom Knack Options</h3>
              <p className="text-base"><span className="link-highlight">Select which Knack objects to backup</span>, and follow our guide to get your backup configured in no-time.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-bell-concierge text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">Managed Knack Backups</h3>
              <p className="text-base">No-code, no infrastructure required. Specifically designed to <span className="link-highlight">backup SaaS applications data without the hassle</span>.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-arrow-rotate-left text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">One-click Restoration</h3>
              <p className="text-base">Restore your <span className="link-highlight">point-in-time Knack data</span> using a simple method. You're covered.</p>
            </div>
          </div>

          <Ui.Typography tag="h3" className="text-2xl text-center mt-20 mb-8">Wait... there's even more...</Ui.Typography>
          <Sections.FeatureSlider tags={["knack"]} />

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>


    <Sections.SectionLearn
      title={<>Wanna learn more about Knack backup?</>}
      text={`Find out more and get started with our Knack  Backup guides`}
      tags={['saas-backup', 'saas-knack']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_knack_backup_service', 'knack_is_there_a_data_limit']}/>

  </Layout.Layout>
)

export default Page
